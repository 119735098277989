.RichEditor-root {
  background: #fff;
  border: 1px solid #ddd;
  font-size: 14px;
  padding: 15px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  .RichEditor-controls {
    font-size: 14px;
    margin-bottom: 5px;
    user-select: none;
    .RichEditor-styleButton {
      color: #999;
      cursor: pointer;
      margin-right: 16px;
      padding: 2px 0;
      display: inline-block;
      &.RichEditor-activeButton {
        color: #5890ff;
      }
    }
  }
  .DraftEditor-root {
    border-top: 1px solid #ddd;
    cursor: text;
    font-size: 16px;
    margin-top: 10px;
    min-height: 10rem;
    padding: 1rem 0;
  }
}
