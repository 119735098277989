.teambuilding-type{
    .content{
        padding: 2rem 0;
    }
    .teambuilding-type-header{
        font-weight: 700;
        margin-top: 2rem !important;
        margin-bottom: 2rem;
    }
    .add-btn{
        display: flex;
        justify-content: flex-end;
    }
}