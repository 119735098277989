@import "../../styles/index.scss";

.drawer-container {
    height: 100%;
    width: 250px;
    box-shadow: 0px 0px 10px 2px rgba(80, 80, 80, 0.3);
    transition: all 300ms;
    overflow: hidden;
    // overflow: auto;
    z-index: 4;
    .drawer-wrap {
        color: $color-black;
        flex-direction: column;
        height: 100%;
    }
    &.compact {
        width: 70px;
        .logo,
        .meta {
            display: none;
        }
        .avatar-container {
            margin-bottom: 30px;
            .name {
                display: none;
            }
            img {
                width: 40px;
                height: 40px;
            }
        }
        .menu {
            .item {
                .text {
                    display: none;
                }
            }
        }
    }
    .logo {
        padding: 1rem 3rem 0;
        font-size: 20px;
        font-weight: 900;
        text-align: center;
    }
    .meta {
        font-size: 12px;
        font-weight: 600;
        text-align: center;
    }
    .avatar-container {
        text-align: center;
        padding: 20px 0px;
        .name {
            font-weight: 600;
        }
        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            transition: all 300ms;
        }
    }
    .menu {
        .item {
            // color: white;
            // padding: 1.6rem 2rem !important;
            &:hover {
                opacity: 0.6;
            }
            cursor: pointer;
            padding: 17px 20px;
            border-left: 5px solid $color-primary-light;

            &.active {
                background-color: $color-primary-light;
                border-left: 5px solid white;
                color: #fff;
            }
            .text {
                text-transform: uppercase;
                font-weight: 800;
                line-height: 0.6;
                margin-left: 2rem;
                font-size: 1.5rem;
            }
            div {
                display: inline-block;
            }
        }
    }
    .anticon-left {
        font-size: 2.2rem;
    }
    .anticon-right {
        font-size: 2.2rem;
    }

    .button-collapse {
        text-align: right;
        width: 100%;
        margin: 1rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }
}
