@import "../../../../styles/index.scss";

.tour-detail-keyinfo {
    .content {
        margin: 2rem;
        .list {
            list-style: none;
            .item {
                display: flex;
                margin: 1rem;
                .icon {
                    font-size: 2.5rem;
                    color: $color-primary-3;
                    display: flex;
                    justify-items: center;
                    align-items: center;
                }
                .item-content {
                    padding: 0 2rem;
                    .en {
                        color: $color-primary-3;
                        // font-style: italic;
                    }
                }
                .icon-delete {
                    cursor: pointer;
                    cursor: pointer;
                    &:hover {
                        color: red;
                    }
                }
            }
        }
    }
}
