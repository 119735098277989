@import "./styles/index.scss";

// utility css
.u-title {
    font-weight: 700;
    color: $color-title;
    margin: 0.1rem 0;
    position: relative;
    &.required {
        &::before {
            content: "*";
            color: red;
            margin-right: 3px;
        }
    }
}

.u-page-detail-modal {
    .btn-group-modal {
        text-align: center;
        margin: 3rem 0 0 0;

        button:first-child {
            margin-right: 2rem;
        }
    }
}

.u-page-detail {
    margin-top: 2rem;
    .top {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
        .heading {
            margin-left: 1rem;
        }
        &.top-without-back-btn {
            .heading {
                margin-left: 0;
            }
        }
    }

    .btn-group {
        text-align: center;
        margin-top: 4rem;
        button {
            margin-right: 2rem;
        }
    }
    .content {
        .ant-form-item {
            .ant-form-item-label {
                width: 100%;
                text-align: left;
                font-weight: 700;
                margin: 0.1rem 0;
                label {
                    color: $color-title;
                }
            }
            .ant-form-item-control {
                input {
                    border: 1px solid rgba(143, 155, 179, 0.2);
                    border-radius: 6px;
                    transition: all 0.5s ease-in-out;
                    padding: 1rem 1.2rem;
                    outline: none;
                    background-color: #f8f8f8;
                }
            }
        }
    }
}

.u-page-data {
    margin-top: 2rem;
    .heading {
        margin-bottom: 4rem;
    }
    .content {
        margin-top: 2rem;
    }
}
.u-description {
    margin-bottom: 5rem;
    font-style: italic;
    font-weight: 600;
    &::before {
        content: "*";
        color: red;
    }
}

.u-banner-page {
    margin-top: 2rem;
    .top {
        display: flex;
        align-items: center;
        margin-bottom: 2rem;
    }
    .banner-body {
        display: flex;
        align-items: flex-start;
        .text-in-banner {
            flex: 0 0 65%;
        }
    }
} 

.u-img-in-table {
    $size: 5rem;
    width: $size;
    height: $size;
    margin: auto;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover;
    }
}
