* {
  margin: 0px;
  padding: 0px;
  box-sizing: inherit;
}

html {
  /* default font-size: 16px -> 62.5% * 16px = 10px */
  /* => 1rem = 10px*/
  font-size: 62.5%;
  scroll-behavior: smooth;
  @media(max-width: $media-sm-screen) {
    font-size: 50%;
  }
}

body {
    box-sizing: border-box;
    font-size: $font-size-paragraph1;
    color: $color-black;
}