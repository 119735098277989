@import '../../styles/index.scss';

.input-common {
    input {
        border: 1px solid rgba($color-gray, .2);
        border-radius: 6px;
        transition: all 0.5s ease-in-out;
        padding: 1rem 1.2rem;
        outline: none;
        background-color: #f8f8f8;
    }
}
