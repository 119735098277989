@import "../../../../styles/index.scss";

.tour-detail-keyinfo {
    .content {
        margin: 2rem;
        .list {
            list-style: none;
            .item {
                display: flex;
                margin: 1rem;
                .icon {
                    margin-right: 1rem;
                    font-size: 2.5rem;
                    color: $color-primary-3;
                    display: flex;
                    justify-items: center;
                    align-items: center;
                }
            }
        }
    }
}
