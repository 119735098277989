
.custom-btn {
    border: none;
    box-shadow: 0 0 10px 2px hsla(0,0%,64.7%,.3);
    border-radius: 3rem !important;
    height: 4rem !important;
    // min-width: 10rem;
}
.btn-add {
    text-align: right !important;
    margin-top: 1rem;
    
}