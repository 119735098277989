.tours{
    .add-btn{
        .button-flex{
            display: flex;
            flex-direction: row;
            .button-glamping{
                margin-left: 2rem;
            }
        }
    }
}