@import "../../../../styles/index.scss";

.banner-tour-detail {
    width: 100%;
    height: 100%;    
    .upload-image-banner-tour-detail {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
            width: 100%;
            height: 40rem;
            margin-right: 0;
            display: flex;
            justify-content: center;
            margin: auto;
            margin-top: 3rem;
            object-fit: contain;
            margin-bottom: 3rem;
        }
    }
}
