@import '../../styles/variable';

.key-info-item {
    display: flex;
    margin: 1rem;
    cursor: pointer;
    transition: all .2s;
    padding: 1rem;
    &:hover {
        background-color: rgba($color-primary-light, .2);
    }
    .icon {
        font-size: 2.5rem;
        color: $color-primary-3;
        display: flex;
        justify-items: center;
        align-items: center;
    }
    .item-content {
        padding: 0 2rem;
        .en {
            color: $color-primary-3;
            // font-style: italic;
        }
    }
    .icon-delete {
        cursor: pointer;
        cursor: pointer;
        font-size: 2rem;
        &:hover {
            color: red;
        }
    }
}
