@import "../../styles/index.scss";

.login-wrap {
    min-height: 100vh;
    position: relative;
    background-image: linear-gradient(
        187deg,
        rgba($color-primary-dark, 0.5),
        rgba($color-primary-3, 0.5),
        #fff 20%,
        #fff 80%,
        rgba($color-primary-dark, 0.5),
        rgba($color-primary-3, 0.5)
    );
    padding-top: 30px;
    padding-bottom: 30px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .card-container {
        background: rgb(0, 147, 233);
        background: linear-gradient(124deg, rgba($color-primary-dark, 1) 0%, rgba($color-primary-3, 1) 100%);
        border-radius: 5px;
        box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.25);
        color: #fff;
        padding-top: 30px;
        padding-bottom: 20px;
        position: relative;
        width: 50rem;
        max-width: 100%;
        text-align: center;
        /* margin-top: 42px;
        margin-bottom: 42px; */
        margin: auto;
        .title-form {
            font-size: $font-size-heading2;
            letter-spacing: 3px;
            font-weight: bold;
            // margin-bottom: 3rem;
        }
        .logo {
            width: 7rem;
            height: 7rem;
        }
        .ant-btn-primary {
            margin-top: 1rem;
            font-weight: bold;
            font-size: $font-size-heading6;
        }
    }
    .content-form {
        padding: 2rem 3rem 0;
    }
    .form-item-custom {
        .ant-form-item-control-input {
            height: $height-btn-small !important;
            .ant-input-affix-wrapper {
                display: flex;
                align-items: center;
            }

            input {
                height: $height-btn-small !important;
            }
        }
    }
    .ant-form-item-control-input {
        input,
        .ant-input-affix-wrapper {
            border-radius: $boder-radious-btn;
            font-size: $font-size-paragraph1;
        }
    }
    // error message
    .ant-form-item-explain {
        text-align: left;
        margin: 0.5rem 0;
    }
}
