.input-img {
    // padding: 1rem;
    width: 100%;
    position: relative;
    .wrap-input {
        display: flex;
        input {
            background-size: 100%;
            height: 10rem;
            background-size: cover;
            background-repeat: no-repeat;
            height: 20rem;
            margin-bottom: 2rem;
            box-shadow: 0 0.6rem 1rem #00000038;
            cursor: pointer;
            border-radius: 0.5rem;

            transition: all 0.5s ease-in-out;
            padding: 1rem 1.2rem;
            outline: none;
            background-color: #f8f8f8;
            &:hover {
                opacity: 0.7;
            }
        }
        .icon {
            font-size: 3rem;
            margin-left: 1rem;
            cursor: pointer;
            margin-right: 1rem;
            &:hover {
                color: red;
            }
        }
    }
}
