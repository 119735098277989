@import "../../styles/index.scss";
.glamping {
	.save {
		display: flex;
		justify-content: flex-end;
		padding-top: 2rem;
	}
	.imageWarp {
		//width: 64rem;
		width: 100%;
		padding-bottom: 5rem;
		padding-top: 5rem;
		//height: 64rem;
		display: flex;
		// padding: 5rem;
		justify-content: center;
		align-items: center;
		border-radius: $border-radius;
		// background-color: yellow;
		.image {
			//width: 62rem;
			display: grid;
			gap: 1rem;
			grid-template-columns: repeat(4, 25rem);
			grid-template-rows: repeat(3, 25rem);
			// grid-template:
			// "square_1 square_2 rectangle_1"
			// "square_3 square_4 rectangle_1"
			// "rectangle_2 rectangle_2 rectangle_2";
			.square_1 {
				grid-column-start: 1;
				grid-column-end: 2;
				grid-row-start: 1;
				grid-row-end: 2;
			}
			.square_2 {
				grid-column-start: 2;
				grid-column-end: 3;
				grid-row-start: 1;
				grid-row-end: 2;
			}
			.square_3 {
				grid-column-start: 1;
				grid-column-end: 2;
				grid-row-start: 2;
				grid-row-end: 3;
			}
			.square_4 {
				grid-column-start: 2;
				grid-column-end: 3;
				grid-row-start: 2;
				grid-row-end: 3;
			}
			.square_5 {
				grid-column-start: 3;
				grid-column-end: 5;
				grid-row-start: 1;
				grid-row-end: 3;
			}
			.rectangle_1 {
				overflow: hidden;
				grid-column-start: 3;
				grid-column-end: 5;
				grid-row-start: 3;
				grid-row-end: 4;
				width: 100%;
				height: 25rem;
        border-radius: $border-radius;
        img{
          width: 100%;
          height: 100%;
		  object-fit: cover;
        }
			}
			.rectangle_2 {
				overflow: hidden;
				grid-column-start: 1;
				grid-column-end: 3;
				grid-row-start: 3;
				grid-row-end: 4;
				width: 100%;
				height: 25rem;
        border-radius: $border-radius;
        img{
          width: 100%;
          height: 100%;
        }
			}
			.bigSquare {
				// width: 51rem;
				// height: 51rem;
				width: 100%;
				height: 100%;
				// padding-top: 100%;
			}
			.square {
				overflow: hidden;
				width: 100%;
				// padding-top: 100%;
				height: 100%;
        border-radius: $border-radius;
        img{
          width: 100%;
          height: 100%;
        }
			}
			.zoom {
				transition: transform 0.2s;
			}
			.zoom:hover {
				transform: scale(1.05);
			}
			img {
				border-radius: $border-radius;
			}
		}
	}
	@media only screen and (max-width: $screen-sm) {
		// .insideWrap{
		.imageWarp {
			padding: 0;
			margin: 0;
			.image {
				//width: 62rem;
				gap: 1rem;
				grid-template-columns: repeat(2, 15rem);
				grid-template-rows: repeat(6, 15rem);
				// grid-template:
				// "square_1 square_2 rectangle_1"
				// "square_3 square_4 rectangle_1"
				// "rectangle_2 rectangle_2 rectangle_2";
				.square_1 {
					grid-column-start: 1;
					grid-column-end: 2;
					grid-row-start: 1;
					grid-row-end: 2;
				}
				.square_2 {
					grid-column-start: 2;
					grid-column-end: 3;
					grid-row-start: 1;
					grid-row-end: 2;
				}
				.square_3 {
					grid-column-start: 1;
					grid-column-end: 2;
					grid-row-start: 2;
					grid-row-end: 3;
				}
				.square_4 {
					grid-column-start: 2;
					grid-column-end: 3;
					grid-row-start: 2;
					grid-row-end: 3;
				}
				.square_5 {
					grid-column-start: 1;
					grid-column-end: 3;
					grid-row-start: 3;
					grid-row-end: 5;
				}
				.rectangle_1 {
					grid-column-start: 1;
					grid-column-end: 3;
					grid-row-start: 5;
					grid-row-end: 6;
					width: 100%;
					height: 100%;
					border-radius: $border-radius;
				}
				.rectangle_2 {
					grid-column-start: 1;
					grid-column-end: 3;
					grid-row-start: 6;
					grid-row-end: 7;
					width: 100%;
					height: 100%;
					border-radius: $border-radius;
				}
				.bigSquare {
					width: 31rem;
					height: 31rem;
				}
				.square {
					width: 100%;
					height: 100%;
					border-radius: $border-radius;
				}
				.zoom {
					transition: transform 0.2s;
				}
				.zoom:hover {
					transform: scale(1.1);
				}
				img {
					border-radius: $border-radius;
					
				}
			}
		}
		// }
	}
}
